<template>
  <ValidationObserver @submit="addNewStructure">
    <UiModalContainer
      footer-classes="flex justify-end gap-3"
      :modal-show="modal"
      :modal-width="50"
      @handleClick="handleClick"
    >
      <template v-slot:header>
        {{ headingTitle }}
      </template>
      <template v-slot>
        <div>
          <InputFieldWrapper>
            <InputBox
              v-model="structureData.title"
              type="text"
              name="TITLE"
              title="TITLE"
              label="TITLE"
              placeholder="TITLE"
              rules="required"
            />
            <InputBox
              v-model="structureData.description"
              type="text"
              name="Description"
              title="Description"
              label="Description"
              placeholder="Description"
            />
          </InputFieldWrapper>
          <div class="flex flex-col sm:flex-row gap-4 lg:gap-5"></div>
          <Divider />
          <!-- Headers -->
          <InputFieldWrapper class="justify-end mt-6">
            <div class="flex justify-end mb-5">
              <UIButton class="px-5" @click="addNewGradeItem">Add New Grade</UIButton>
            </div>
          </InputFieldWrapper>

          <InputFieldWrapper
            v-for="(grade, index) in gradingList"
            :key="index"
            custom-classes="items-start"
          >
            <InputBox
              v-model="gradingList[index].title"
              type="text"
              :name="`TITLE + ${index}`"
              title="Title"
              label="Title"
              :is-not-translated="true"
              placeholder="Title"
              rules="required"
            />
            <InputBox
              v-if="!grade.isDefault"
              v-model.number="gradingList[index].value"
              type="text"
              :name="`VALUE + ${index}`"
              :is-not-translated="true"
              title="Value"
              label="Value"
              placeholder="Value"
              rules="required"
            />
            <span v-if="!grade.isDefault" class="flex gap-2 sm:gap-3 w-full">
              <InputBox
                v-model="gradingList[index].scale"
                type="text"
                :name="`SCALE + ${index}`"
                title="Scale"
                label="Scale"
                :is-not-translated="true"
                placeholder="i.e 4.0"
                rules="required|double"
              />
              <span
                v-if="!grade.isDefault && gradingList.length > 2"
                class="self-center"
                @click="removeGradeItem(index)"
              >
                <icon
                  class="icon cursor-pointer"
                  width="12"
                  height="12"
                  color="mahroon-strong"
                  icon="cross"
                />
              </span>
            </span>
            <InputBox
              v-if="grade.isDefault"
              type="text"
              name="VALUE"
              title="Value"
              label="Value"
              :is-not-translated="true"
              :disabled="grade.isDefault"
              :placeholder="`Less ${grade.value}`"
            />
            <span v-if="grade.isDefault" class="w-full">
              <InputBox
                v-model="gradingList[index].scale"
                type="text"
                name="SCALE"
                title="SCALE"
                label="SCALE"
                placeholder="SCALE_4"
                rules="required|double"
              />
            </span>
          </InputFieldWrapper>
        </div>
      </template>
      <template v-slot:footer>
        <UIButton v-i18n="dashboard" :disabled="isLoading" @click="handleClick('close')">
          Cancel
        </UIButton>
        <UIButton button="primary" :disabled="isLoading">
          <template v-if="isLoading">
            <Loader />
          </template>
          <template v-else>
            {{ saveBtnTitle }}
          </template>
        </UIButton>
      </template>
    </UiModalContainer>
  </ValidationObserver>
</template>

<script>
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import InputBox from '@components/UiElements/UiInputBox.vue'
import { Form as ValidationObserver } from 'vee-validate'
import { mapActions } from 'vuex'
import Loader from '@components/BaseComponent/Loader.vue'
import Divider from '@src/components/Divider.vue'
import UIButton from '@src/components/UiElements/UIButton.vue'
import icon from '@components/icons/icon.vue'
import { objectDeepCopy } from '@utils/generalUtil'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'

export default {
  components: {
    UiModalContainer,
    InputBox,
    ValidationObserver,
    Loader,
    Divider,
    UIButton,
    icon,
    InputFieldWrapper,
  },
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['toggle'],
  data() {
    return {
      newGrade: { title: '', value: '', scale: '', isDefault: false },
      defaultGrade: { title: '', value: '', scale: '', isDefault: true },
      structureData: {
        title: '',
        description: '',
      },
      gradingList: [{ title: '', value: 100, scale: '', isDefault: false }],
      isLoading: false,
    }
  },
  computed: {
    saveBtnTitle() {
      return this.edit ? 'Update' : 'Save'
    },
    headingTitle() {
      return this.edit ? 'Edit GPA Grading Structure' : 'Add GPA Grading Structure'
    },
  },
  watch: {
    gradingList: {
      deep: true,
      handler(list) {
        let values = []
        list.forEach((grade) => {
          if (!grade.isDefault && typeof grade.value === 'number') values.push(grade.value)
        })
        if (list.length > 1)
          list[list.length - 1]['value'] = values.length ? Math.min(...values) : 100
      },
    },
  },
  created() {
    if (this.edit) this.setFormData()
    else this.gradingList = [...this.gradingList, { ...this.defaultGrade }]
  },
  methods: {
    addNewGradeItem(event) {
      if (event) event.preventDefault()
      let newGrade = objectDeepCopy(this.newGrade)
      this.gradingList.splice(this.gradingList.length - 1, 0, newGrade)
    },
    removeGradeItem(idx) {
      this.gradingList.splice(idx, 1)
    },
    handleClick(eventName) {
      switch (eventName) {
        case 'close':
          this.$emit('toggle')
          break
      }
    },
    async addNewStructure() {
      this.isLoading = true
      let payload = { structure: this.getPayload() }
      if (this.edit) payload.id = this.edit?.id
      let [res, err] = await this.addGPAGradingStructure(payload)
      this.isLoading = false
      if (res) this.$emit('toggle', true)
    },
    getPayload() {
      return {
        title: this.structureData.title,
        description: this.structureData.description,
        default_grade_title: this.gradingList[this.gradingList.length - 1].title,
        default_less_than: this.gradingList[this.gradingList.length - 1].value,
        default_scale: this.gradingList[this.gradingList.length - 1].scale,
        grading_scales: this.getGradingScalesPayload().filter((item) => !!item),
      }
    },
    getGradingScalesPayload() {
      return this.gradingList.map((gradingItem) => {
        if (!gradingItem.isDefault)
          return {
            title: gradingItem.title,
            greater_than_equal_to: gradingItem.value,
            scale: gradingItem.scale,
          }
      })
    },
    setFormData() {
      this.structureData.title = this.edit?.title || ''
      this.structureData.description = this.edit?.description || ''
      this.gradingList = this.edit.grading_scales.map((grade) => {
        return {
          title: grade.title,
          value: grade.greater_than_equal_to,
          scale: grade.scale,
          isDefault: false,
        }
      })
      this.gradingList = [
        ...this.gradingList,
        {
          title: this.edit.default_grade_title,
          value: this.edit.default_less_than,
          scale: this.edit.default_scale,
          isDefault: true,
        },
      ]
    },
    ...mapActions('exams', ['addGPAGradingStructure']),
  },
}
</script>
